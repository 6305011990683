.nav-bar {
  .menu-button {
    border-color: transparent;
    white-space: nowrap;

    @media (max-width: 992px) {
      margin: 0 12px 0 12px;
    }
  }

  .active {
    color: #00A09D;
  }

  .login-button {
    @media (max-width: 992px) {
      margin: 12px 0 12px 0;
    }
  }

  .navbar-links {
    @media (max-width: 767px) {
      //border-bottom: 1px solid #d7dae2;
    }
  }

  .dropdown-menu {
    border: none;
    box-shadow: none;
  }
  .dropdown-toggle {
    padding: 0;
  }
}
