.borrow {
  .stage-number {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: white;
  }
  .progress-bar-bg {
    z-index: 0;
    background-color: #d7dae2;
    height: 1px;
    max-width: 344px;
    margin-bottom: -30px;
  }
  .progress-bar-blue {
    background-color: #41b9fc;
    height: 1px;
  }
  .border-blue {
    border: 1px solid #41b9fc;
  }
  .border-gray {
    border: 1px solid #95C5C4;
  }
  .metrics-div {
    margin-top: 72px;
    margin-bottom: 32px;
  }
}
