.footer {
  border-top: 1px solid #95C5C4;
}
.status-circle {
  width: 12px;
  height: 12px;
  border-radius: 999px;
  margin-top: 4px;
  margin-bottom: 4px;

  &.success{
    background-color: #00A09D;
  }
  &.error{
    background-color: #FF8363;
  }
}
