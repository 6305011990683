/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Work+Sans:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700;800&display=swap');

$theme-colors: (
  "primary": #00a09d,
);

body {
  color: #00262a;
  overflow-y: scroll; //!important;
  padding-right: 0 !important;
}

.app {
  //min-height: 100vh;
  display: flex;
  flex-direction: column;

  .main-content {
    flex-grow: 1;
    //min-height: 100vh;
  }
  .link:hover {
    text-decoration: none !important;
    color: #09152b !important;
    cursor: pointer;
  }
}
.border-thin {
  border: 1px solid #95c5c4;
  border-radius: 4px;
}
.primarybutton {
  border: none !important;

  &:focus {
    border: none !important;
    outline: none !important;
  }
}
*[role="button"] {
  cursor: default !important;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.w-80 {
  @media (max-width: 768px) {
    width: 95vw !important;
  }
}
.cards-container-wrapper {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  @media (min-width: 1024px) {
    // min-height: 650px;
  }
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-content: center;
}
.text-black {
  color: #09152b;
}
.text-gray {
  color: #394455;
}
.hover-text-lightest-gray:hover {
  color: #6b7380 !important;
}
.text-lightest-gray {
  color: #6b7380;
}
.text-green {
  color: #82c43c;
}
.text-yellow {
  color: #f5ac37;
}

.pointer {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}

.input {
  border: none;
  max-width: 344px;
  text-align: center;

  &:focus {
    outline: none;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.btn-link:focus {
  text-decoration: none;
}
.container-wrapper {
  max-width: 1400px;
  margin: auto;
}
.underline {
  text-decoration: underline;
}

.max-w-xxs {
  max-width: 17rem;
}

.tooltip-shadow {
  box-shadow: 0px 10px 18px 0px #000000 20% !important;
  border-radius: 4px !important;
}

.bn-onboard-custom {
  z-index: 20 !important;
}
.modal-shadow {
  box-shadow: 0px 20px 40px rgba(167, 171, 170, 0.24);
}

.shadow-under:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  box-shadow: 0px -2px 8px rgba(167, 171, 170, 0.24);
}
