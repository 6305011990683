.slider {
  input[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    background: white;
    box-shadow: -405px 0 0 400px #03565c;
    border-radius: 50%;
  }
}
