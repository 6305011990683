.metrics-card {
  @media (min-width: 892px) {
    width: 270px;
  }
  @media (max-width: 892px) and (min-width: 768px) {
    width: 30vw !important;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
}
.main-card {
  @media (min-width: 892px) {
    width: 560px !important;
  }
  @media (max-width: 892px) and (min-width: 768px) {
    width: 60vw !important;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
}
.alpha-banner {
  margin: 0 auto 0 auto;

  @media (min-width: 892px) {
    margin: 0 8px 0 8px;
  }
  @media (max-width: 892px) and (min-width: 768px) {
    width: 90vw;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
}
.goback-header {
  background-color: rgba(0, 160, 157, 0.1);
}
