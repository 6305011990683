//Hero headline
.type-h1 {
  font-family: Libre Baskerville;
  font-size: 40px;
  font-style: normal;
  line-height: 52px;
  letter-spacing: 0em;

  @media (min-width: 1024px) {
    font-size: 52px;
    line-height: 64px;
  }
}
//Hero headline
.type-h1-sans{
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  line-height: 52px;
  letter-spacing: 0em;

  @media (min-width: 1024px) {
    font-size: 52px;
    line-height: 64px;
  }
}

//Section title
.type-h2 {
  font-family: Libre Baskerville;
  font-size: 32px;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 0em;

  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 52px;
  }
}

//Section title
.type-h2-sans {
  font-family: Work Sans;
  font-size: 32px;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 0em;

  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 52px;
  }
}
//Small section title
.type-h3 {
  font-family: Libre Baskerville;
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0em;

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 44px;
  }
}

//Small section title
.type-h3-sans {
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0em;

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 44px;
  }
}

//Form section main action
.type-h4 {
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: -0.02em;
}

//Sub Head, Footer Title , Tabs, Navigation
.type-h5 {
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.02em;
}

//Lead Paragraph
.type-p {
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.02em;
}

//Paragraph
.type-p-lg {
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0em;
}

//P small
.type-p-sm {
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
}

//X small, Terms
.type-p-xs {
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0em;
}

//Smallest button
.type-p-xxs {
  font-family: Work Sans;
  font-size: 8px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0em;
}


//Caption, Copyright,
.type-caption {
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0em;
}

//Label
.type-label {
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
}

//Button
.type-button {
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.02em;
}

//Button small
.type-button-sm {
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0em;
}
