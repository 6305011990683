.processing-screen {
  max-width: 400px;
  margin: auto;
  text-align: center;

  .processing-text {
    margin-top: -60px;
  }
  a:hover {
    color: #09152b;
  }
}
